(function($) {
    "use strict"; // Start of use strict

    // jQuery for page scrolling feature - requires jQuery Easing plugin
    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 50)
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
    });

    // Highlight the top nav as scrolling occurs
    $('body').scrollspy({
        target: '.navbar-fixed-top',
        offset: 51
    });

    // Closes the Responsive Menu on Menu Item Click
    $('.navbar-collapse ul li a:not(.dropdown-toggle)').click(function() {
        $('.navbar-toggle:visible').click();
    });

    // Offset for Main Navigation
    $('#mainNav').affix({
        offset: {
            top: 100
        }
    })

    /* Contact form
        ================================================== */
    $("#contact-form").validate({
        ignore: [],
        rules: {
            anrede: {
                required: true,
                minlength: 1
            },
            forename: {
                required: true,
                minlength: 2
            },
            name: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true
            },
            phone: {
                required: false,
            },
            company: {
                required: false,
            },
            check_newsletter: {
                required: true,
            }
        },
        messages: {
            anrede: {
                required: "Bitte wählen Sie Ihre Anrede aus."
            },
            forename: {
                required: "Bitte geben Sie Ihren Vornamen ein.",
                minlength: "Ihr Vorname muss mindestens aus zwei Zeichen bestehen."
            },
            name: {
                required: "Bitte geben Sie Ihren Nachnamen ein.",
                minlength: "Ihr Nachname muss mindestens aus zwei Zeichen bestehen."
            },
            company: {
                required: "Bitte geben Sie Ihr Unternehmen ein.",
                minlength: "Der Name Ihres Unternehmens muss aus mindestens zwei Zeichen bestehen."
            },
            email: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
            phone: "Bitte geben Sie Ihre Telefonnummer ein."
        },
        onfocusout: false,
        invalidHandler: function(form, validator) {
            var errors = validator.numberOfInvalids();
            if (errors) {
                validator.errorList[0].element.focus();
            }
        },

        /*submitHandler: function(form) {
            $(form).ajaxSubmit({
                target: '#contact-form-result-success',
                type: "POST",
                data: $(form).serialize(),
                url: "test.php",

                success: function(response) {
                    console.log(response);
                    $("#contact-form").fadeOut(200, function() {
                        $('#contact-form-result-success').fadeIn(1500);
                        $('#contact-form-result-success').append('<p class="nl-small">Vielen Dank für Ihr Interesse an unserer Energiepreiskommunikation!</p><p class="nl-small">Das Whitepaper können Sie sich hier herunterladen.</p><a href="pdf/trurnit_Whitepaper_Energiepreiskommunikation.pdf" class="btn top-buffer-20" target="_blank"><strong>Jetzt Whitepaper herunterladen</strong></a>');
                    });
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'Forms',
                        eventAction: 'send',
                        eventLabel: 'Lead'
                    });
                },
                error: function(response) {
                    console.log(response);
                    $('#contact-form-result-error').fadeIn(1500);
                }
            });
        }*/
    });
    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: DE (German, Deutsch)
     */
    $.extend($.validator.messages, {
        required: "Dieses Feld ist ein Pflichtfeld.",
        maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
        minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
        rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
        email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
        url: "Geben Sie bitte eine gültige URL ein.",
        date: "Bitte geben Sie ein gültiges Datum ein.",
        number: "Geben Sie bitte eine Nummer ein.",
        digits: "Geben Sie bitte nur Ziffern ein.",
        equalTo: "Bitte denselben Wert wiederholen.",
        range: $.validator.format("Geben Sie bitte einen Wert zwischen {0} und {1} ein."),
        max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
        min: $.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
        creditcard: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein."
    });


    /* Formular
    ================================================================================ */
    $("#check_angebot").on('change', function() {
        if ($(this).is(":checked")) {
            $('.check_angebot_zusatzinfo').slideDown(300);
            $('#check_angebot_haushaltsgroesse').focus();
        }
        if (!$(this).is(":checked")) {
            $('.check_angebot_zusatzinfo').slideUp(300);
        }
    });


    /* EU-Cookie
    ================================================================================ */
    var eleu = jQuery("#trurnit-eucookie");
    var buttoneu = jQuery("#trurnit-eucookie button");
    if (-1 === document.cookie.indexOf("trurnit-eucookie")) {
        setTimeout(function() { eleu.slideDown("slow"); }, 1000);
    }
    buttoneu.on("click", function() {
        var date = new Date;
        date.setDate(date.getDate() + 365), document.cookie = "trurnit-eucookie=" + escape("hide") + ";path=/;expires=" + date.toGMTString();
        eleu.slideUp("slow");
    });

    /* Video Overlay
    ================================================================================ */
    $('#play-video').on('click', function(e) {
        e.preventDefault();
        $('#video-overlay').addClass('open');
        //$('.video-overlay').append('<video autoplay controls class="fillWidth layerVideo"><source src="assets/night.mp4" type="video/mp4" /><source src="assets/night.webm" type="video/webm" /></video>');
        $('.video-overlay .fillWidth .layerVideo').append('<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/EPNXkuNioLE?rel=0&amp;controls=1&amp;autoplay=1&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>');
        $('body').addClass('layered');
    });

    $('.video-overlay,.video-overlay-close').on('click', function(e) {
        e.preventDefault();
        close_video();
    });


     /* Parallax Effect
    ================================================================================ */
    $(window).on('load scroll', function() {
        var scrolled = $(this).scrollTop();
        $('.intro-text.fadeOut').css({
            'transform': 'translate3d(0, ' + -(scrolled * 0.2) + 'px, 0)', // parallax (20% scroll rate)
            'opacity': 1 - scrolled / 300 // fade out at 400px from top
        });
    });


    /* Sticky Form
    ================================================================================ */
    /*$(window).load(function() {
        stick();
    });

    $(window).resize(function() {
        var windowWidth = $(window).width();

        if (windowWidth > 992) {
            stick();
        } else {
            unstick();
        }
    });

    function stick() {
        $("#formBox").sticky({
            topSpacing: 100,
            bottomSpacing: 200,
            responsiveWidth: true
        });
    }

    function unstick() {
        $('#formBox').unstick();
    }*/

})(jQuery); // End of use strict

/* Close Video Overlay
================================================================================ */
$(document).keyup(function(e) {
    if (e.keyCode === 27) { close_video(); }
});

function close_video() {
    $('.video-overlay.open').removeClass('open');
    //$('.video-overlay video').get(0).pause();
    //$('.video-overlay video').remove();
    $('.video-overlay iframe').remove();
    $('body').removeClass('layered');
};

